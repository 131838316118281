<template>
  <div>
    <app-section-title
      :title="title"
      :isViewMore="isViewMore"
      to="latestTestimonies"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 app-latest-testimonies__div-margin"
          v-for="resource in testimonies"
          :key="resource.id"
        >
          <app-testimonie-card
            :heading="resource.author.name"
            :createdAt="resource.created_at"
            :description="resource.message"
            :profileImage="resource.author.picture"
          ></app-testimonie-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTestimonieCard from './cards/AppTestimonieCard.vue';
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppTestimonieCard,
    AppSectionTitle,
  },
  props: {
    testimonies: {
      type: Array,
    },
    isViewMore: {
      default: false,
    },
  },
  data() {
    return {
      title: 'Latest Testimonies',
    };
  },
};
</script>

<style scoped>
.container {
  padding: 10px 0px 10px 0px;
}
.app-latest-testimonies__div-margin {
  margin-top: 10px;
}
</style>
