<template>
  <div class="app-popular-groups__main--div">
    <app-section-title
      title="Popular Groups"
      :isViewMore="isViewMore"
      to="findGroups"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 app-popular-groups__card-top"
          v-for="resource in popularGroups"
          :key="resource.id"
        >
          <app-group-card
            :id="resource.id"
            :name="resource.name"
            :picture="resource.picture"
            :detail="resource.description"
            :eventdetails="resource.meeting_day"
            :attendant="resource.members_aggregate.aggregate.count"
          ></app-group-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupCard from './cards/AppPopularGroupCard.vue';
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppGroupCard,
    AppSectionTitle,
  },
  props: {
    popularGroups: {
      type: Array,
    },
  },
  data() {
    return {
      isViewMore: true,
    };
  },
};
</script>

<style scoped>
.app-popular-groups__main--div {
  margin-top: 20px;
}
.container {
  padding: 10px 0px 10px 0px;
}
.app-popular-groups__card-top {
  margin-top: 10px;
}
</style>
