<template>
  <div class="recent-videos-container__div--main">
    <div class="container recent-videos-container__div--main">
      <div class="row">
        <app-recent-videos
          :videoResources="recentVideos"
          v-if="recentVideos && recentVideos.length > 0"
        ></app-recent-videos>
      </div>
    </div>
  </div>
</template>

<script>
import AppRecentVideos from '@/components/library/AppRecentVideos.vue';
import ListRecentVideos from '@/gql/training/ListRecentVideos.gql';

export default {
  components: {
    AppRecentVideos,
  },
  data() {
    return {};
  },
  apollo: {
    recentVideos: ListRecentVideos,
  },
};
</script>

<style scoped>
.recent-videos-container__div--main {
  padding-top: 15px;
  background-color: var(--cs-gray-02);
}
</style>
