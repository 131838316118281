<template>
  <div class="app-group-profile__div-main" v-if="group">
    <div class="cs-textstyle-page-title mb-3">{{ group.name }}</div>
    <img class="app-group-profile__banner-image" :src="group.picture" />
    <div class="container app-group-profile__div-container">
      <div class="row">
        <div class="col-md-9">
          <h5 class="cs-textstyle-detail-heading">DESCRIPTION</h5>
          <div class="cs-textstyle-informative-paragraph">
            {{ group.description }}
          </div>
          <div class="app-group-profile__div--share">
            <div class="app-group-profile__share_details">SHARE</div>
            <app-social-media :id="group.id"></app-social-media>
          </div>
        </div>
        <div class="col-md-3" v-if="leaders.length">
          <h5 class="cs-textstyle-detail-heading">GROUP LEADERS</h5>
          <app-hosted-by
            class="cs-textstyle-informative-paragraph"
            v-for="leader in leaders"
            :leader="leader"
            :key="leader.id"
          ></app-hosted-by>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSocialMedia from '@/components/general/AppSocialMedia.vue';
import AppHostedBy from '@/components/groups/AppHostedBy.vue';

export default {
  components: {
    AppSocialMedia,
    AppHostedBy,
  },
  props: {
    group: {
      type: Object,
    },
  },
  computed: {
    leaders() {
      // Returns the list of all leaders as user objects
      return (
        this.group &&
        this.group.members.filter((m) => !!m.is_leader).map((l) => l.user)
      );
    },
  },
};
</script>

<style scoped>
.app-group-profile__div-main {
  margin-top: 20px;
}
.app-group-profile__banner-image {
  width: 100%;
}
.app-group-profile__div--share {
  display: flex;
}
.app-group-profile__share_details {
  padding-top: 7px;
  padding-right: 15px;
  color: var(--cs-gray-04);
}
.app-group-profile__div-container {
  background-color: var(--cs-gray-00);
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
