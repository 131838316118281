<template>
  <div class="app-news-detail__main-container" v-if="news && news.length">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div
            class="app-news-item"
            v-bind:class="{
              'app-news-details__div-hide': index === 0,
              'app-news-details__div-show': index === 1,
              'app-news-details__containt-top': index === 3,
              'app-news-details__div--video': utils.isAttachmentType(
                resource.attachment_type,
                'video'
              ),
            }"
            v-for="(resource, index) in news"
            :key="resource.id"
            @click="onGoToNewsItem(resource.id)"
          >
            <div class="app-news-details__cstag-wrapper">
              <cs-small-tag
                class="news-details_cstag-left"
                v-if="resource.category"
                >{{ resource.category }}</cs-small-tag
              >
              <app-news-update
                :isCardWithoutVideo="
                  utils.isAttachmentType(resource.attachment_type, 'video') &&
                  resource.attachments.length
                "
                :newsItem="resource"
              ></app-news-update>
            </div>
          </div>
        </div>
        <div class="col-md-6 app-news-details__top-gutter">
          <div class="app-news-details__header--icon">
            <b-icon icon="book" font-scale="2"></b-icon>
          </div>
          <h2 class="app-news-details__header-top cs-textstyle-page-subtitle">
            NEWS UPDATES
          </h2>
          <p
            class="
              app-news-details__header-top
              cs-textstyle-informative-paragraph
            "
          >
            So wie der Sonntag fur die Gemeinschaft der Christen steht, soll der
            Freitag der Tag Weise, die Orte order Methoden sind komplett
            flexibel. Es soll sich ein Christentum formen, dass wie Jesus ein
            Licht in dieser Welt ist. Und zwar regelmaBig, geschult,
            ausgerustet, mit Liebe, Freude und Gottes Kraft, Junger, die Junger
            machen
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNewsUpdate from '@/components/home/AppNewsUpdate.vue';
import utils from '@/services/utils';

export default {
  components: {
    AppNewsUpdate,
  },
  props: {
    news: {
      type: Array,
    },
  },
  data() {
    return {
      utils,
      isCardWithoutVideo: true,
    };
  },
  methods: {
    onGoToNewsItem(id) {
      this.$router.push(`/Community/LatestNews/${id}`);
    },
  },
};
</script>

<style scoped>
.app-news-detail__main-container {
  background-color: var(--cs-gray-08);
  padding: 50px 0px 50px 0px;
  color: var(--cs-gray-00);
}
.app-news-details__top-gutter {
  padding-top: 200px;
}
.app-news-details__header--icon {
  background-color: var(--cs-primary-dark);
  width: 52px;
  height: 50px;
  padding: 10px 0px 0px 10px;
  color: var(--cs-gray-00);
}
.app-news-details__header-top {
  padding-top: 30px;
}
.app-news-details__div-hide {
  opacity: 0.1;
}
.app-news-details__div-show {
  margin-left: 120px;
  margin-top: -100px;
}
.app-news-details__containt-top {
  margin-left: 120px;
}
.app-news-details__div--video {
  margin-left: 70px;
}
@media (max-width: 768px) {
  .app-news-details__div-show {
    margin-left: 0px;
  }
  .app-news-details__containt-top {
    margin-left: 0px;
  }
  .app-news-details__div--video {
    margin-left: 0px;
  }
  .app-news-details__cstag-wrapper {
    width: auto !important;
  }
  .app-news-details__top-gutter {
    padding-top: 100px;
  }
}
.app-news-details__cstag-wrapper {
  background-color: var(--cs-gray-00);
  border-radius: 5px;
  padding: 10px;
  width: 363px;
  margin-top: 30px;
}
.news-details_cstag-left {
  margin-left: 5px;
}
.app-news-item {
  position: relative;
}
</style>
