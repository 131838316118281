<template>
  <div class="library-container__div--main">
    <div class="container library-container__div--main">
      <div class="row">
        <div v-if="loading" class="col-md-12 library-container__loading-holder">
          <cs-spinner />
        </div>
        <template v-else>
          <div class="col-md-3">
            <app-library-filter
              :categories="categories"
              @selectedFilter="onSelectFilter"
            ></app-library-filter>
          </div>
          <div class="col-md-9">
            <app-popular-resources
              :popularResources="featuredArticles"
              v-if="featuredArticles && featuredArticles.length > 0"
              :isViewMore="
                totalArticles &&
                totalArticles.aggregate.count > limit.featuredArticles
              "
            ></app-popular-resources>
            <app-recent-videos
              :videoResources="recentVideos"
              v-if="recentVideos && recentVideos.length > 0"
              :isViewMore="
                totalVideos && totalVideos.aggregate.count > limit.recentVideos
              "
            ></app-recent-videos>
            <app-trending-products
              :trendingResources="
                featuredProducts.slice(0, limit.featuredProducts)
              "
              v-if="featuredProducts && featuredProducts.length > 0"
              :isViewMore="featuredProducts.length > limit.featuredProducts"
            ></app-trending-products>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppLibraryFilter from '@/components/library/AppLibraryFilter.vue';
import AppPopularResources from '@/components/library/AppPopularResources.vue';
import AppRecentVideos from '@/components/library/AppRecentVideos.vue';
import AppTrendingProducts from '@/components/library/AppTrendingProducts.vue';
import ListTrainingCategories from '@/gql/training/ListTrainingCategories.gql';
import ListFeaturedArticles from '@/gql/training/ListFeaturedArticles.gql';
import ListRecentVideos from '@/gql/training/ListRecentVideos.gql';
import FeaturedProducts from '@/gql/store/FeaturedProducts.gql';

export default {
  components: {
    AppLibraryFilter,
    AppPopularResources,
    AppRecentVideos,
    AppTrendingProducts,
  },
  computed: {
    loading() {
      const queries = [
        'categories',
        'totalArticles',
        'featuredArticles',
        'totalVideos',
        'recentVideos',
        'featuredProducts',
      ];
      const loadingQueries = queries.filter(
        (query) => this.$apollo.queries[query].loading
      );
      return loadingQueries.length > 0;
    },
  },
  data() {
    return {
      selectedFilter: null,
      limit: {
        featuredArticles: 2,
        recentVideos: 2,
        featuredProducts: 1,
      },
    };
  },
  apollo: {
    categories: ListTrainingCategories,
    totalArticles: ListFeaturedArticles,
    featuredArticles: {
      query: ListFeaturedArticles,
      variables() {
        return {
          id: this.selectedFilter,
          limit: this.limit.featuredArticles,
        };
      },
    },
    totalVideos: ListRecentVideos,
    recentVideos: {
      query: ListRecentVideos,
      variables() {
        return {
          id: this.selectedFilter,
          limit: this.limit.recentVideos,
        };
      },
    },
    featuredProducts: FeaturedProducts,
  },
  methods: {
    onSelectFilter(id) {
      console.log(id);
      this.selectedFilter = id;
    },
  },
};
</script>

<style scoped>
.library-container__div--main {
  background-color: var(--cs-gray-02);
}
.library-container__loading-holder {
}
</style>
