<template>
  <div class="groups-container__main-div">
    <div class="container-fluid groups-container__main-div">
      <div class="row">
        <div class="col-md-8 groups-search-container__main-div">
          <div class="groups-search__main-div">
            <app-group-search
              :isBack="true"
              @searchQuery="onSearchQuery"
            ></app-group-search>
          </div>
          <div class="map-container__main-div" v-if="results">
            <app-google-map
              height="70vh"
              :groups="results"
              :showUserLocation="true"
              :key="googleMapRerenderKey"
              :center="userLocation"
            ></app-google-map>
          </div>
        </div>
        <div class="col-md-4 groups-near-list__main-div" v-if="results">
          <app-groups-near-list :groups="results"></app-groups-near-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupSearch from '@/components/groups/AppGroupSearch.vue';
import AppGoogleMap from '@/components/general/AppGoogleMap.vue';
import AppGroupsNearList from '@/components/groups/AppGroupsNearList.vue';
import ListGroups from '@/gql/groups/ListGroups.gql';
import utils from '@/services/utils';

export default {
  components: {
    AppGroupSearch,
    AppGoogleMap,
    AppGroupsNearList,
  },

  apollo: {
    groups: {
      query: ListGroups,
      variables() {
        return {
          query: `%${this.query}%`,
        };
      },
    },
  },
  data() {
    return {
      query: '',
      googleMapRerenderKey: 0,
      userLocation: utils.defaultUserLocation,
    };
  },
  mounted() {
    this.geolocate();
  },
  computed: {
    results() {
      const fields = ['name', 'location'];
      this.reRenderGoogleMap();
      return (
        this.groups &&
        this.groups
          .filter((g) =>
            fields.some(
              (field) =>
                g[field] &&
                g[field].toLowerCase().includes(this.query.toLowerCase())
            )
          )
          .map((g) => {
            g.distance = utils.calculateDistance(
              g.google_place.latitude,
              g.google_place.longitude,
              this.userLocation.lat,
              this.userLocation.lng
            );
            return g;
          })
          .sort((a, b) => a.distance - b.distance)
      );
    },
  },
  methods: {
    onSearchQuery(query) {
      this.query = query;
      this.reRenderGoogleMap();
    },
    reRenderGoogleMap() {
      this.googleMapRerenderKey += 1;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(
        (center) => {
          this.userLocation = {
            lat: center.coords.latitude,
            lng: center.coords.longitude,
          };
        },
        () => {
          this.userLocation = utils.defaultUserLocation;
        }
      );
    },
  },
};
</script>

<style scoped>
/* .groups-container__main-div {
  background-color: var(--cs-gray-02);
} */
.map-container__main-div .card {
  border: none !important;
}
.map-container__main-div .card .card-body {
  padding: 0 !important;
}
.groups-search-container__main-div {
  padding-left: 0;
  padding-right: 0;
}
.groups-search__main-div {
  padding-right: calc(var(--bs-gutter-x) * 1);
  padding-left: calc(var(--bs-gutter-x) * 1);
}
.groups-near-list__main-div {
  padding-left: 0;
  padding-right: 0;
}
.map-container__main-div {
  margin-top: 12px;
}
</style>
