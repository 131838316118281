<template>
  <div class="app-group-near-list__main-div">
    <h3 class="cs-textstyle-item-heading">Search Results</h3>
    <div class="container">
      <div class="app-group-near-list__list">
        <div class="col-md-12" v-for="resource in groups" :key="resource.id">
          <app-group-near-card
            :id="resource.id"
            :name="resource.name"
            :picture="resource.picture"
            :detail="resource.description"
            :eventdetails="resource.meeting_day"
            :attendant="resource.members_aggregate.aggregate.count"
            :distance="resource.distance"
          ></app-group-near-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupNearCard from './cards/AppGroupNearCard.vue';

export default {
  components: {
    AppGroupNearCard,
  },
  props: {
    groups: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.cs-textstyle-item-heading {
  padding-left: 1rem;
}
.app-group-near-list__main-div {
  padding-top: 20px;
  background: var(--cs-gray-01);
  border: 1px solid var(--cs-gray-02);
  opacity: 1;
}
.app-group-near-list__list {
  height: 78vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.container {
  padding: 10px 0px 10px 0px;
}
</style>
