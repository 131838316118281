<template>
  <div class="app-news-update__main-container">
    <cs-card
      :media-padding="padding"
      media-position="left"
      :no-divider="nodivider"
    >
      <div v-if="isCardWithoutVideo" slot="media">
        <cs-video-player
          :picture="newsItem.picture"
          :src="newsItem.attachments[0].url"
          slot="media"
        ></cs-video-player>
      </div>
      <div slot="header">
        <div class="cs-textstyle-paragraph-bold">
          {{ newsItem.title }}
        </div>
      </div>
      <div slot="body">
        <div class="cs-textstyle-paragraph-small">
          {{ newsItem.summary }}
        </div>
      </div>
    </cs-card>
  </div>
</template>

<script>
export default {
  props: {
    padding: {
      default: 'media-padding',
    },
    nodivider: {
      type: Boolean,
      default: false,
    },
    isCardWithoutVideo: {
      default: false,
    },
    newsItem: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.app-news-update__main-container {
  margin-top: 10px;
  width: 353px;
}
.cs-card {
  border: none !important;
}
@media (max-width: 768px) {
  .app-news-update__main-container {
    width: auto !important;
  }
}
</style>
