import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueMoment from 'vue-moment';
import App from './App.vue';
import router from './router';
import 'cs-components';
import 'cs-components/dist/csuite.css';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// Import global style.css
import './assets/css/styles.css';
import './assets/icons/css/styles.css';

import '@/assets/css/design-token.css';
import apolloClient from '@/services/apollo';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueMoment);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDHLsHlBVVO7sKW0IrjNn-ImEM-5ERfCfQ',
    libraries: 'places',
  },
});

new Vue({
  router,
  BootstrapVue,
  IconsPlugin,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');

Vue.use(BootstrapVue);
