<template>
  <div class="featured-products-container__div--main">
    <div class="container featured-products-container__div--main">
      <div class="row">
        <app-trending-products
          :trendingResources="featuredProducts"
          v-if="featuredProducts && featuredProducts.length > 0"
        ></app-trending-products>
      </div>
    </div>
  </div>
</template>

<script>
import AppTrendingProducts from '@/components/library/AppTrendingProducts.vue';
import FeaturedProducts from '@/gql/store/FeaturedProducts.gql';

export default {
  components: {
    AppTrendingProducts,
  },
  data() {
    return {};
  },
  apollo: {
    featuredProducts: FeaturedProducts,
  },
};
</script>

<style scoped>
.featured-products-container__div--main {
  padding-top: 15px;
  background-color: var(--cs-gray-02);
}
</style>
