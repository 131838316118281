<template>
  <b-card>
    <div class="app-testimonie-card__div--container">
      <cs-profile
        :picture="profileImage"
        :name="heading"
        :detail="createdAt | moment('DD MMM hh:mm A')"
        :avatar-position="position"
        avatar-size="small"
      >
      </cs-profile>
      <div
        class="app-testimonie-card__div--top cs-textstyle-paragraph"
        v-html="description"
      ></div>
      <!-- <div class="app-testimonie-card__div--top">
        <img v-if="DescriptionImage !== ''" :src="DescriptionImage" />
      </div> -->
    </div>
  </b-card>
</template>

<script>
export default {
  components: {},
  props: {
    heading: {},
    createdAt: {},
    description: {},
    profileImage: {},
    DescriptionImage: {},
    position: {
      type: String,
      default: 'left',
    },
  },
};
</script>

<style scoped>
.app-testimonie-card__div--container {
  width: 100%;
  height: 100%;
}
.cs-profile {
  justify-content: left !important;
  display: block !important;
}
.app-testimonie-card__div--top {
  padding-top: 10px;
}
@media (max-width: 768px) {
  img {
    width: 100%;
  }
}
</style>
