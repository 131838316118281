<template>
  <div class="app-gallery__main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="app-gallery__image-icon">
            <img src="@/assets/cs-icons-church.svg" />
          </div>
          <div class="app-gallery__header--top">
            <h2 class="cs-textstyle-page-subtitle">Gallery</h2>
          </div>
          <div v-if="images" class="app-gallery__images-list">
            <VueSlickCarousel
              :dots="true"
              :arrows="false"
              v-bind="slickOptions"
            >
              <img
                class="app-gallery__image"
                v-for="(image, index) of images"
                :key="index"
                :src="image.url"
              />
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    images: {
      required: true,
    },
  },
  data() {
    return {
      slickOptions: {
        autoplay: true,
        draggable: true,
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.app-gallery__main-container {
  background-color: var(--cs-gray-02);
  padding: 50px 0px 50px 0px;
  text-align: center;
}
.app-gallery__image-icon {
  background-color: var(--cs-primary-dark);
  width: 52px;
  height: 50px;
  margin: auto;
  padding: 5px 10px 0px 10px;
  color: var(--cs-gray-00);
  display: flex;
  justify-content: center;
}
.app-gallery__header--top {
  padding-top: 30px;
}

.app-gallery__image {
  padding: 8px;
  height: 322px;
}
</style>
