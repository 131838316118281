<template>
  <div class="news-item-container__background--color">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <b-card class="news-item-body">
            <div v-if="article">
              <div class="ion-padding">
                <div class="cs-textstyle-page-title">{{ article.title }}</div>
                <div class="metadata">
                  <div class="timestamp">
                    Posted {{ article.created_at | moment('from') }}
                  </div>
                </div>
                <cs-quill-viewer :value="article.body"></cs-quill-viewer>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetNewsItem from '@/gql/news/GetNewsItem.gql';

export default {
  apollo: {
    article: {
      query: GetNewsItem,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style scoped>
.news-item-body {
  margin-top: 15px;
  margin-bottom: 15px;
}
.news-item-container__background--color {
  background-color: var(--cs-gray-02);
}
div >>> .ql-editor {
  padding: 0px;
  padding-top: 8px;
}
</style>
