<template>
  <div class="app-social-media__display">
    <div class="app-social-media__icon-padding">
      <cs-social-icon-button
        :href="share.facebook"
        :network="'facebook'"
        :size="'small'"
        slot="social-icons"
      ></cs-social-icon-button>
    </div>
    <div class="app-social-media__icon-padding">
      <cs-social-icon-button
        :href="share.twitter"
        :network="'twitter'"
        :size="'small'"
        slot="social-icons"
      ></cs-social-icon-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      share: {
        facebook: null,
        twitter: null,
      },
    };
  },
  mounted() {
    const url = `https://${window.location.host}/#/GroupProfile/${this.id}`;
    this.share.facebook = `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    this.share.twitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
  },
};
</script>

<style scoped>
.app-social-media__display {
  display: flex;
}
.app-social-media__icon-padding {
  padding: 5px;
}
</style>
