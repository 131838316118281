<template>
  <div class="app-meets-at__main-div" v-if="group">
    <div class="d-flex justify-content-start">
      <div><b-icon icon="geo-alt-fill"></b-icon>&nbsp;</div>
      <h3 class="cs-textstyle-section-heading">
        Meets every {{ meetingDay }} at {{ group.meeting_time }} at
      </h3>
    </div>
    <div class="cs-textstyle-section-heading">
      {{ group.google_place.name }}
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <app-google-map :groups="[...group]"></app-google-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGoogleMap from '../general/AppGoogleMap.vue';

export default {
  components: {
    AppGoogleMap,
  },
  props: {
    group: {
      type: Object,
    },
  },
  computed: {
    meetingDay() {
      return (
        this.group.meeting_day.charAt(0).toUpperCase() +
        this.group.meeting_day.toLowerCase().slice(1)
      );
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.app-meets-at__main-div {
  margin-top: 20px;
}
.container {
  padding: 10px 0px 10px 0px;
}
</style>
