var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.news && _vm.news.length)?_c('div',{staticClass:"app-news-detail__main-container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},_vm._l((_vm.news),function(resource,index){return _c('div',{key:resource.id,staticClass:"app-news-item",class:{
            'app-news-details__div-hide': index === 0,
            'app-news-details__div-show': index === 1,
            'app-news-details__containt-top': index === 3,
            'app-news-details__div--video': _vm.utils.isAttachmentType(
              resource.attachment_type,
              'video'
            ),
          },on:{"click":function($event){return _vm.onGoToNewsItem(resource.id)}}},[_c('div',{staticClass:"app-news-details__cstag-wrapper"},[(resource.category)?_c('cs-small-tag',{staticClass:"news-details_cstag-left"},[_vm._v(_vm._s(resource.category))]):_vm._e(),_c('app-news-update',{attrs:{"isCardWithoutVideo":_vm.utils.isAttachmentType(resource.attachment_type, 'video') &&
                resource.attachments.length,"newsItem":resource}})],1)])}),0),_c('div',{staticClass:"col-md-6 app-news-details__top-gutter"},[_c('div',{staticClass:"app-news-details__header--icon"},[_c('b-icon',{attrs:{"icon":"book","font-scale":"2"}})],1),_c('h2',{staticClass:"app-news-details__header-top cs-textstyle-page-subtitle"},[_vm._v(" NEWS UPDATES ")]),_c('p',{staticClass:"\n            app-news-details__header-top\n            cs-textstyle-informative-paragraph\n          "},[_vm._v(" So wie der Sonntag fur die Gemeinschaft der Christen steht, soll der Freitag der Tag Weise, die Orte order Methoden sind komplett flexibel. Es soll sich ein Christentum formen, dass wie Jesus ein Licht in dieser Welt ist. Und zwar regelmaBig, geschult, ausgerustet, mit Liebe, Freude und Gottes Kraft, Junger, die Junger machen ")])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }