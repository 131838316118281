<template>
  <div class="app-the-vision__main-container">
    <div class="container">
      <div class="row app-the-vision__row-top">
        <h2 class="col-sm-12 col-md-2 cs-textstyle-page-subtitle">
          THE<br />VISION
        </h2>
        <div class="col-sm-12 col-md-10 cs-textstyle-informative-paragraph">
          The greatest Friday in history changed our lives. When Jesus hung on
          the cross and shouted: "it is finished", the price for salvation was
          paid for everyone who believes. We will go out and share this
          wonderful gospel every Friday in every city. It will change us and the
          people we will meet, Christians will be mobilized, encouraged, trained
          and will go out together. The world will see us and the gospel will be
          heard every week.
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <iframe
            width="100%"
            height="565"
            src="https://www.youtube.com/embed/huTraf3za6U?controls=0"
            title="FridayOutreach Movement"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      src: 'https://samplelib.com/lib/preview/mp4/sample-5s.mp4',
    };
  },
};
</script>

<style scoped>
.app-the-vision__main-container {
  background-color: var(--cs-gray-08);
  color: var(--cs-gray-00);
}
.app-the-vision__row-top {
  padding-top: 150px;
}
.row {
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .app-the-vision__row-top {
    padding-top: 60px;
  }
}
</style>
