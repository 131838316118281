<template>
  <div>
    <app-section-title
      title="Recommended Products"
      :isViewMore="isViewMore"
      to="storeProducts"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 app-trending-products__aricle-card_top"
          v-for="resource in trendingResources"
          :key="resource.id"
          @click="onGoToStoreProduct(resource.id)"
        >
          <cs-article-card
            :picture-padding="padding"
            :picture="resource.images && resource.images[0].src"
            :tags="getTags(resource.categories)"
            :title="resource.name"
            :summary="resource.short_description | stripHTML"
            :author="resource.price"
          >
          </cs-article-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppSectionTitle,
  },
  props: {
    padding: {
      default: 'picture-padding',
    },
    trendingResources: {
      type: Array,
    },
    isViewMore: {
      default: false,
    },
  },
  data() {
    return {
      summary: '',
    };
  },
  methods: {
    getTags(tags) {
      return tags.reduce((newArr, category) => {
        newArr.push(category.name);
        return newArr;
      }, []);
    },

    onGoToStoreProduct(id) {
      this.$router.push(`/Library/Store/${id}`);
    },
  },
  filters: {
    stripHTML(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ');
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0px 0px 0px 0px;
}
.app-trending-products__aricle-card_top {
  margin-top: 15px;
  cursor: pointer;
}
</style>
