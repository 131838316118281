<template>
  <b-card>
    <cs-profile
      @click="ShowGroupProfile"
      :picture="picture"
      :name="name"
      :detail="detail"
      avatar-position="left"
      avatar-size="medium"
    >
    </cs-profile>
    <div
      class="
        d-flex
        justify-content-between
        popular-group-card_div-top
        cs-textstyle-paragraph-small-bold
      "
    >
      <div class="popular-group-card_eventdetails">
        Meets every
        {{ textCapatalize(eventdetails.toLowerCase()) }}
      </div>
      <div><i class="cs-icons-group-filled"></i> {{ attendant }}</div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    id: {},
    name: {},
    picture: {},
    detail: {},
    eventdetails: {},
    attendant: {},
  },
  methods: {
    ShowGroupProfile() {
      this.$router.push(`/GroupProfile/${this.id}`);
    },
    textCapatalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
  },
};
</script>

<style scoped>
.popular-group-card_div-top {
  padding-top: 10px;
  color: var(--cs-gray-04);
}
</style>
