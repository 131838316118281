<template>
  <div class="event-detail-container__main-div">
    <div class="container event-detail-container__main-div">
      <div class="row">
        <div class="col-md-12">
          <app-event-details></app-event-details>
          <app-upcoming-group-events></app-upcoming-group-events>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppEventDetails from '@/components/groups/AppEventDetails.vue';
import AppUpcomingGroupEvents from '@/components/groups/AppUpcomingGroupEvents.vue';

export default {
  components: {
    AppEventDetails,
    AppUpcomingGroupEvents,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.event-detail-container__main-div {
  background-color: var(--cs-gray-02);
}
</style>
