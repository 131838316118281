<template>
  <div
    class="
      latest-testimonies-container
      latest-testimonies-container__background--color
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <app-latest-testimonies
            :testimonies="testimonies"
          ></app-latest-testimonies>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLatestTestimonies from '@/components/community/AppLatestTestimonies.vue';
import ListTestimonies from '@/gql/testimonies/ListTestimonies.gql';

export default {
  components: {
    AppLatestTestimonies,
  },
  data() {
    return {};
  },
  apollo: {
    testimonies: ListTestimonies,
  },
};
</script>

<style scoped>
.latest-testimonies-container {
  padding-top: 15px;
}
.latest-testimonies-container__background--color {
  background-color: var(--cs-gray-02);
}
</style>
