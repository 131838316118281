<template>
  <div class="app-god-use__main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="app-god-use__image-icon">
            <img src="@/assets/cs-icons-church.svg" />
          </div>
          <div class="app-god-use__header--top">
            <h2 class="cs-textstyle-page-subtitle">GOD WILL USE YOU</h2>
          </div>
          <div class="app-god-use__body">
            <div
              class="
                cs-textstyle-informative-paragraph
                app-god-use__header--top
                mb-3
              "
            >
              You will be part of God's mission team - if you say "Yes" to God's
              call. We will help you with the training and good ideas to reach
              people in a good and natural way. You are not alone. We will do it
              together.
            </div>
            <div class="cs-textstyle-informative-paragraph">
              We believe in discipleship. It is also very important to stay in
              contact with people during the week through prayer and Bible
              reading.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.app-god-use__main-container {
  background-color: var(--cs-gray-02);
  padding: 50px 0px 50px 0px;
  text-align: center;
}
.app-god-use__image-icon {
  background-color: var(--cs-primary-dark);
  width: 52px;
  height: 50px;
  margin: auto;
  padding: 5px 10px 0px 10px;
  color: var(--cs-gray-00);
  display: flex;
  justify-content: center;
}
.app-god-use__header--top {
  padding-top: 30px;
}
.app-god-use__body {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>
