,
<template>
  <div
    class="app-others-say__main--container"
    v-if="testimonies && testimonies.length"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="app-others-say__header--icon">
            <b-icon icon="chat" font-scale="2"></b-icon>
          </div>
          <h2 class="cs-textstyle-page-subtitle app-others-say__header-top">
            SEE WHAT OTHERS<br />HAVE TO SAY
          </h2>
        </div>
        <div
          class="col-md-6"
          v-for="(resource, index) in testimonies"
          :key="index"
        >
          <div :class="{ 'app-others-say__container--top': index % 2 === 0 }">
            <img
              v-bind:src="
                resource.author.picture ||
                'https://via.placeholder.com/150/?text=Image'
              "
              class="app-others-say__image"
            />
            <div class="app-others-say__p-story">
              <div class="cs-textstyle-informative-paragraph">
                {{ resource.message }}
                <br />
                <br />
                <p class="cs-textstyle-paragraph">
                  {{ resource.author.name
                  }}<span v-if="resource.author.age_range"
                    >, {{ resource.author.age_range }} years</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonies: {
      type: Array,
    },
  },
  data() {
    return {
      otherImages: 'https://picsum.photos/300',
    };
  },
};
</script>

<style scoped>
.app-others-say__main--container {
  background-color: var(--cs-gray-08);
  padding: 50px 0px 50px 0px;
  color: var(--cs-gray-00);
}
.app-others-say__image {
  width: 208px;
  height: 208px;
}
.app-others-say__header-top {
  padding-top: 40px;
}
.app-others-say__container--top {
  padding-top: 150px;
}
.app-others-say__p-story div {
  margin-left: 70px;
  margin-top: -40px;
}
.app-others-say__p-story div p {
  color: var(--cs-primary-base);
}
.app-others-say__header--icon {
  background-color: var(--cs-primary-dark);
  width: 52px;
  height: 50px;
  padding: 10px 0px 0px 10px;
  color: var(--cs-gray-00);
}
@media (max-width: 768px) {
  .app-others-say__container--top {
    padding-top: 50px;
  }
}
</style>
