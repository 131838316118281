<template>
  <div class="app-footer__main--div">
    <div class="app-footer__flex-container">
      <div>
        <img src="@/assets/vector.svg" alt="" class="app-footer__brand-icon" />
      </div>
      <div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'library' }" active-class=""
            >Library</router-link
          >
        </div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'Home' }" active-class=""
            >Privacy Policy</router-link
          >
        </div>
      </div>
      <div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'groups' }" active-class=""
            >Groups</router-link
          >
        </div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'community' }" active-class=""
            >Testimonials</router-link
          >
        </div>
      </div>
      <div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'Home' }" active-class=""
            >Terms of Use</router-link
          >
        </div>
      </div>
      <div>
        <div class="app-footer__link">
          <router-link :to="{ name: 'Home' }" active-class=""
            >SHARE</router-link
          >
        </div>
        <div class="app-footer__link">
          <a :href="share.facebook" target="_blank">
            <i class="cs-icons-facebook-filled"></i></a
          >&nbsp;
          <a :href="share.twitter" target="_blank">
            <i class="cs-icons-twitter-filled"></i></a
          >&nbsp;
        </div>
      </div>
      <div>
        <div class="app-footer__link">
          <a class="app-footer__email" href="mailto:info@fridayoutreach.com"
            >info@fridayoutreach.com</a
          >
        </div>
        <div class="app-footer__link">
          <a class="app-footer__email" href="https://www.fridayoutreach.com"
            >www.fridayoutreach.com</a
          >
        </div>
      </div>
      <div class="app-footer__link">
        <cs-button
          :style="{
            '--cs-button-color': '#FFFFFF',
            '--cs-button-text-color': '#000000',
          }"
          href="https://app.fridayoutreach.com"
          target="_blank"
          >Download App</cs-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-footer__main--div {
  padding: 20px 0px 20px 0px;
  background-color: var(--cs-gray-08);
  color: var(--cs-gray-03);
}
.app-footer__flex-container {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
}
.app-footer__brand-icon {
  width: 60px;
  height: 84px;
}
.app-footer__link {
  padding-top: 10px;
}
.app-footer__link a {
  color: var(--cs-gray-04);
}
.app-footer__link i {
  color: var(--cs-gray-01);
}
.app-footer__email {
  color: var(--cs-primary-base);
}
@media (max-width: 800px) {
  .app-footer__flex-container {
    flex-direction: column;
    padding: 15px 10px 15px 10px;
  }
}
</style>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      share: {
        facebook: null,
        twitter: null,
      },
    };
  },
  mounted() {
    const url = `https://${window.location.host}`;
    this.share.facebook = `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    this.share.twitter = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      url
    )}`;
  },
};
</script>
