<template>
  <div class="app-recent-videos__main-div">
    <app-section-title
      title="Recent Videos"
      :isViewMore="isViewMore"
      to="recentVideos"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-6 app-recent-videos__card_top"
          v-for="resource in videoResources"
          :key="resource.id"
          @click="onGoToVideo(resource.id)"
        >
          <app-card-containt
            :heading="resource.category.name"
            :updatedTime="resource.updated_at"
            :libraryHeader="resource.title"
            :libraryDescription="resource.summary"
            :type="resource.attachment_type"
            :picture="resource.picture"
          ></app-card-containt>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCardContaint from './cards/AppCardContaint.vue';
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppCardContaint,
    AppSectionTitle,
  },
  props: {
    videoResources: {
      type: Array,
    },
    isViewMore: {
      default: false,
    },
  },
  methods: {
    onGoToVideo(id) {
      this.$router.push(`/Library/PopularResources/${id}`);
    },
  },
};
</script>

<style scoped>
.app-recent-videos__main-div {
  margin-top: 20px;
}
.app-recent-videos__card_top {
  margin-top: 15px;
}
.container {
  padding: 10px 0px 10px 0px;
}
</style>
