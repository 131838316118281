<template>
  <div>
    <app-the-vision></app-the-vision>
    <app-get-involved :groups="groupMarkers"></app-get-involved>
    <app-news-detail :news="news"></app-news-detail>
    <app-god-use></app-god-use>
    <app-others-say :testimonies="testimonies"></app-others-say>
    <app-gallery :images="images"></app-gallery>
    <app-everyone-together></app-everyone-together>
  </div>
</template>

<script>
import AppTheVision from '@/components/home/AppTheVision.vue';
import AppGetInvolved from '@/components/home/AppGetInvolved.vue';
import AppNewsDetail from '@/components/home/AppNewsDetail.vue';
import AppGallery from '@/components/home/AppGallery.vue';
import AppGodUse from '@/components/home/AppGodUse.vue';
import AppOthersSay from '@/components/home/AppOthersSay.vue';
import AppEveryoneTogether from '@/components/home/AppEveryoneTogether.vue';
import ListNews from '@/gql/news/ListNews.gql';
import ListTestimonies from '@/gql/testimonies/ListTestimonies.gql';
import ListGallery from '@/gql/home/ListGallery.gql';

export default {
  components: {
    AppTheVision,
    AppGetInvolved,
    AppNewsDetail,
    AppGallery,
    AppGodUse,
    AppOthersSay,
    AppEveryoneTogether,
  },
  apollo: {
    news: {
      query: ListNews,
      variables() {
        return {
          language: 'en',
        };
      },
    },
    testimonies: ListTestimonies,
    images: ListGallery,
  },
  data() {
    return {
      groupMarkers: [
        {
          google_place: {
            latitude: 51.5413,
            longitude: 9.9158,
          },
        },
        {
          google_place: {
            latitude: 49.1989,
            longitude: 8.1186,
          },
        },
        {
          google_place: {
            latitude: 52.3759,
            longitude: 9.732,
          },
        },
        {
          google_place: {
            latitude: 51.0504,
            longitude: 13.7373,
          },
        },
        {
          google_place: {
            latitude: 50.0782,
            longitude: 8.2398,
          },
        },
        {
          google_place: {
            latitude: 50.1109,
            longitude: 8.6821,
          },
        },
        {
          google_place: {
            latitude: 49.9929,
            longitude: 8.2473,
          },
        },
        {
          google_place: {
            latitude: 51.4344,
            longitude: 6.7623,
          },
        },
        {
          google_place: {
            latitude: 51.4556,
            longitude: 7.0116,
          },
        },
        {
          google_place: {
            latitude: 51.9607,
            longitude: 7.6261,
          },
        },
      ],
    };
  },
};
</script>

<style scoped></style>
