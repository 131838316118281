<template>
  <div class="app-everyone-together__main-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12 app-everyone-together__div-center">
          <h2 class="cs-textstyle-page-subtitle">
            EVERYONE CAN REACH SOMEONE. TOGETHER WE CAN REACH THE WORLD!
          </h2>
          <p
            class="
              cs-textstyle-informative-paragraph
              app-everyone-together__div-margin
            "
          >
            If Friday Outreach is in our calendar on a fixed date we will see a
            huge impact in our lives and in the life of others.
          </p>
          <div class="container">
            <div
              class="
                row
                justify-content-center
                app-everyone-together__div-top-margin
              "
            >
              <div class="col-6 col-md-2 app-everyone-together__div-margin">
                <b-icon
                  class="cs-textstyle-page-title"
                  icon="calendar3"
                ></b-icon
                ><br />
                <h3 class="cs-textstyle-section-heading">7:00 PM</h3>
                <p class="cs-textstyle-paragraph-bold">Uhr treffen</p>
              </div>
              <div class="col-6 col-md-2">
                <i class="icons8-user cs-textstyle-page-title"></i><br />
                <h3 class="cs-textstyle-section-heading">35 MIN</h3>
                <p class="cs-textstyle-paragraph-bold">Teaching und Gebet</p>
              </div>
              <div class="col-6 col-md-2">
                <i class="icons8-marker cs-textstyle-page-title"></i><br />
                <h3 class="cs-textstyle-section-heading">90 MIN</h3>
                <p class="cs-textstyle-paragraph-bold">
                  Einsatz in Zweier-Teams
                </p>
              </div>
              <div class="col-6 col-md-2">
                <i class="icons8-user cs-textstyle-page-title"></i><br />
                <h3 class="cs-textstyle-section-heading">35 MIN</h3>
                <p class="cs-textstyle-paragraph-bold">Teaching und Gebt</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.app-everyone-together__div-center {
  margin-top: 150px;
  text-align: center;
}
.app-everyone-together__main-container {
  background-color: var(--cs-primary-dark);
  background-image: url('../../assets/Dark.png');
  padding: 50px 0px 50px 0px;
  color: var(--cs-gray-00);
  position: relative;
  height: 650px;
}
.app-everyone-together__div-margin {
  margin-top: 8px;
}
.app-everyone-together__div-top-margin {
  margin-top: 50px;
}
@media (max-width: 768px) {
  .app-everyone-together__div-center {
    margin-top: 0px;
  }
}
</style>
