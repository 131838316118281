<template>
  <div class="store-product-container__background--color">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <b-card class="store-product-body">
            <app-cover
              v-if="product && product.images[0]"
              :picture="product.images[0].src"
              alt=""
            ></app-cover>

            <div v-if="product">
              <div class="cs-textstyle-page-title">{{ product.name }}</div>
              <div class="price-bar">
                <div class="cs-textstyle-page-subtitle price">
                  ${{ product.price }}
                </div>
                <cs-button :href="product.permalink" target="_blank"
                  >Buy Now</cs-button
                >
              </div>
              <div class="cs-textstyle-item-heading">Description</div>
              <div
                class="cs-textstyle-paragraph"
                v-html="product.description"
              ></div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCover from '@/components/general/cover.vue';
import GetProduct from '@/gql/store/GetProduct.gql';

export default {
  components: {
    AppCover,
  },
  apollo: {
    product: {
      query: GetProduct,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style scoped>
.store-product-body {
  margin-top: 15px;
  margin-bottom: 15px;
}
.store-product-container__background--color {
  background-color: var(--cs-gray-02);
}
.cs-textstyle-page-title {
  margin-top: 4px;
}
.price {
  color: var(--cs-primary-base);
}
.price-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cs-textstyle-item-heading {
  margin-bottom: 15px;
}
</style>
