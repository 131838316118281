<template>
  <div class="groups-container__main-div">
    <div class="container groups-container__main-div">
      <div class="row">
        <div
          v-if="$apollo.queries.groups.loading"
          class="col-md-12 groups-container__loading-holder"
        >
          <cs-spinner />
        </div>
        <div v-else class="col-md-12">
          <app-group-search></app-group-search>
          <app-popular-groups :popularGroups="groups"></app-popular-groups>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupSearch from '@/components/groups/AppGroupSearch.vue';
import AppPopularGroups from '@/components/groups/AppPopularGroups.vue';
import ListGroups from '@/gql/groups/ListGroups.gql';

export default {
  components: {
    AppGroupSearch,
    AppPopularGroups,
  },
  apollo: {
    groups: {
      query: ListGroups,
      variables() {
        return {
          limit: 10,
        };
      },
    },
  },
};
</script>

<style scoped>
.groups-container__main-div {
  background-color: var(--cs-gray-02);
}
</style>
