<template>
  <div class="popular-resources-container__div--main">
    <div class="container popular-resources-container__div--main">
      <div class="row">
        <app-popular-resources
          :popularResources="articles"
          v-if="articles && articles.length > 0"
        ></app-popular-resources>
      </div>
    </div>
  </div>
</template>

<script>
import AppPopularResources from '@/components/library/AppPopularResources.vue';
import ListArticles from '@/gql/training/ListArticles.gql';

export default {
  components: {
    AppPopularResources,
  },
  data() {
    return {};
  },
  apollo: {
    articles: ListArticles,
  },
};
</script>

<style scoped>
.popular-resources-container__div--main {
  background-color: var(--cs-gray-02);
}
</style>
