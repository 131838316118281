<template>
  <div class="app-upcoming-group-event__div-main">
    <app-section-title
      :title="title"
      :isViewMore="isViewMore"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-12 app-upcoming-group-event__card-padding"
          v-for="resource in eventDetails"
          :key="resource.id"
        >
          <app-upcoming-group-event-card
            :eventImage="resource.eventImage"
            :eventDate="resource.eventDate"
            :eventTime="resource.eventTime"
            :eventHeader="resource.eventHeader"
            :eventTitle="resource.eventTitle"
            :eventAddress="resource.eventAddress"
            :eventParticipants="resource.eventParticipants"
          ></app-upcoming-group-event-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppUpcomingGroupEventCard from './cards/AppUpcomingGroupEventCard.vue';
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppUpcomingGroupEventCard,
    AppSectionTitle,
  },
  data() {
    return {
      isViewMore: true,
      title: 'Upcoming group events',
      eventDetails: [
        {
          id: 1,
          eventImage: 'https://picsum.photos/315',
          eventDate: 'TUE, JAN 14,',
          eventTime: '6:00 PM',
          eventHeader: 'CEO WOMEN COMMUNITY',
          eventTitle:
            'Global travel and vacations luxury travel on a tight budget',
          eventAddress: 'Building A ul. Fabryczna 29 53-609',
          eventParticipants: '230 members',
        },
        {
          id: 2,
          eventImage: 'https://picsum.photos/316',
          eventDate: 'TUE, JAN 14,',
          eventTime: '6:00 PM',
          eventHeader: 'CEO WOMEN COMMUNITY',
          eventTitle:
            'Global travel and vacations luxury travel on a tight budget',
          eventAddress: 'Building A ul. Fabryczna 29 53-609',
          eventParticipants: '230 members',
        },
        {
          id: 3,
          eventImage: 'https://picsum.photos/317',
          eventDate: 'TUE, JAN 14,',
          eventTime: '6:00 PM',
          eventHeader: 'CEO WOMEN COMMUNITY',
          eventTitle:
            'Global travel and vacations luxury travel on a tight budget',
          eventAddress: 'Building A ul. Fabryczna 29 53-609',
          eventParticipants: '230 members',
        },
        {
          id: 4,
          eventImage: 'https://picsum.photos/318',
          eventDate: 'TUE, JAN 14,',
          eventTime: '6:00 PM',
          eventHeader: 'CEO WOMEN COMMUNITY',
          eventTitle:
            'Global travel and vacations luxury travel on a tight budget',
          eventAddress: 'Building A ul. Fabryczna 29 53-609',
          eventParticipants: '230 members',
        },
        {
          id: 5,
          eventImage: 'https://picsum.photos/319',
          eventDate: 'TUE, JAN 14,',
          eventTime: '6:00 PM',
          eventHeader: 'CEO WOMEN COMMUNITY',
          eventTitle:
            'Global travel and vacations luxury travel on a tight budget',
          eventAddress: 'Building A ul. Fabryczna 29 53-609',
          eventParticipants: '230 members',
        },
      ],
    };
  },
};
</script>

<style scoped>
.app-upcoming-group-event__div-main {
  margin-top: 20px;
}
.container {
  padding: 10px 0px 10px 0px;
}
.app-upcoming-group-event__card-padding {
  margin-top: 10px;
}
</style>
