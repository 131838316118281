<template>
  <b-card>
    <div v-if="markers.length">
      <GmapMap
        :center="center"
        :zoom="2"
        class="app-google-map__size"
        :style="{ height: height }"
      >
        <GmapInfoWindow
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <a :href="`/#/GroupProfile/${currentGroupId}`" v-if="currentGroupId"
            >{{ currentGroupName }} <br />
            {{ infoContent }}</a
          >
          <div v-else>
            {{ currentGroupName }} <br />
            {{ infoContent }}
          </div>
        </GmapInfoWindow>
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :icon="
            showUserLocation && m.infoText === 'You'
              ? 'https://img.icons8.com/ios-filled/30/000000/place-marker--v1.png'
              : 'https://img.icons8.com/office/40/000000/place-marker--v1.png'
          "
          @click="toggleInfoWindow(m, index)"
        />
        <div slot="visible">
          <div
            style="
              bottom: 0;
              left: 0;
              background-color: #bf0012;
              color: white;
              position: absolute;
              z-index: 100;
            "
          >
            {{ statusText }}
          </div>
        </div>
      </GmapMap>
    </div>
    <br />
  </b-card>
</template>

<script>
import utils from '@/services/utils';

export default {
  name: 'GoogleMap',
  props: {
    height: {
      type: String,
      default: '400px',
    },
    groups: {
      type: Array,
    },
    showUserLocation: {
      default: false,
    },
    center: {
      type: Object,
      default: () => utils.defaultUserLocation,
    },
  },
  data() {
    return {
      markers: [],
      statusText: '',
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      currentGroupId: null,
      currentGroupName: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  mounted() {
    this.markers = [];
    if (this.groups) {
      this.groups.forEach((g) =>
        this.markers.push({
          position: {
            lat: g.google_place.latitude,
            lng: g.google_place.longitude,
          },
          infoText: g.google_place.name,
          id: g.id,
          name: g.name,
        })
      );
    }
    if (this.showUserLocation) {
      this.markers.push({ position: this.center, infoText: 'You' });
    }
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      if (!marker.infoText) {
        return;
      }
      this.infoWindowPos = marker.position;
      this.infoContent = marker.infoText;

      this.currentGroupId = marker.id;
      this.currentGroupName = marker.name;

      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      // if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
};
</script>

<style scoped>
.app-google-map__size {
  width: 100%;
}
</style>
