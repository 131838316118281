<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div @click="ShowUpcomingGroupEvent">
        <div class="d-flex justify-content-between">
          <div>
            <img class="upcoming-group-event-card__image" :src="eventImage" />
            <div class="upcoming-group-event__time">
              {{ eventDate }}<br />{{ eventTime }}
            </div>
          </div>
          <div>
            <div class="upcoming-group-event__div-details">
              <div
                class="
                  upcoming-group-event__div-event
                  cs-textstyle-paragraph-small-bold
                "
              >
                <i class="cs-icons-twitter-filled"></i>{{ eventHeader }}
              </div>
              <div
                class="
                  upcoming-group-event__div-event
                  cs-textstyle-paragraph-small-bold
                "
              >
                {{ eventTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div @click="ShowUpcomingGroupDetails">
        <div class="upcoming-group-event__div-details">
          <div
            class="cs-textstyle-paragraph-small upcoming-group-event__address"
          >
            <b-icon icon="geo-alt-fill"></b-icon> {{ eventAddress }}
          </div>
          <div
            class="cs-textstyle-paragraph-small upcoming-group-event__address"
          >
            <i class="cs-icons-group-filled"></i> {{ eventParticipants }}
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    eventImage: {},
    eventDate: {},
    eventTime: {},
    eventHeader: {},
    eventTitle: {},
    eventAddress: {},
    eventParticipants: {},
  },
  methods: {
    ShowUpcomingGroupEvent() {
      this.$router.push('/UpcomingEventDetails');
    },
    ShowUpcomingGroupDetails() {
      this.$router.push('/EventDetails');
    },
  },
};
</script>

<style scoped>
.cs-card {
  border: none !important;
}
.card-body {
  padding: 0px !important;
}
.upcoming-group-event__address {
  color: var(--cs-gray-05);
  padding: 10px;
}
.upcoming-group-event__div-event {
  padding: 10px;
}
.upcoming-group-event__div-details {
  display: table-cell;
  height: 120px;
  vertical-align: middle;
}
.upcoming-group-event__time {
  margin-top: -60px;
  padding-left: 10px;
  color: var(--cs-gray-00);
}
.upcoming-group-event-card__image {
  width: 120px;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .d-flex {
    flex-direction: column;
  }
  .upcoming-group-event-card__image {
    padding: 15px 15px 15px 15px;
    width: 100%;
  }
  .upcoming-group-event__time {
    margin-top: -80px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}
</style>
