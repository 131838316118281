<template>
  <div>
    <div :class="getClassForHomeBackground">
      <div :class="getClassForHomeBackgroundColor">
        <navbar-menu></navbar-menu>
        <div v-if="isAppHomeShownOrNot">
          <app-home-main></app-home-main>
        </div>
      </div>
      <div class="router-holder">
        <router-view></router-view>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import AppFooter from './views/Home/AppFooter.vue';
import AppHomeMain from './components/home/AppHomeMain.vue';
import NavbarMenu from './views/Home/NavbarMenu.vue';

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHomeMain,
    NavbarMenu,
  },
  data() {
    return {};
  },
  computed: {
    isAppHomeShownOrNot() {
      return this.$route.name.toLowerCase() === 'home';
    },
    getClassForHomeBackground() {
      return this.$route.name.toLowerCase() === 'home'
        ? 'app app__background--image'
        : '';
    },
    getClassForHomeBackgroundColor() {
      return this.$route.name.toLowerCase() === 'home'
        ? 'app app__background--color-opacity'
        : 'app__background--color';
    },
  },
};
</script>

<style>
.app {
  height: 998px;
}
.app__background--image {
  background-image: url('./assets/Image.png');
}
.app__background--color-opacity {
  background-color: rgba(0, 0, 0, 0.8);
  position: relative;
}
.app__background--color {
  background-color: var(--cs-gray-08);
}
.router-holder {
  ---header-height: 126px;
  ---footer-height: 124px;
  min-height: calc(100vh - var(---header-height) - var(---header-height));
  background-color: var(--cs-gray-02);
}
</style>
