function readingTime(text) {
  let minutes = 0;
  const contentAsString = JSON.stringify(text);
  const words = contentAsString.split(' ').length;
  const wordsPerMinute = 200;

  minutes = Math.ceil(words / wordsPerMinute);

  return minutes;
}

function isAttachmentType(data, type) {
  return data && data.includes(type);
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  }
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;
  return dist;
}

const defaultUserLocation = { lat: 51.1657, lng: 10.4515 };

export default {
  readingTime,
  isAttachmentType,
  calculateDistance,
  defaultUserLocation,
};
