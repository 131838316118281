<template>
  <div class="latest-news-container__background--color">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <app-latest-news :news="news"></app-latest-news>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLatestNews from '@/components/community/AppLatestNews.vue';
import ListNews from '@/gql/news/ListNews.gql';

export default {
  components: {
    AppLatestNews,
  },
  data() {
    return {};
  },
  apollo: {
    news: {
      query: ListNews,
      variables() {
        return {
          language: 'en',
        };
      },
    },
  },
};
</script>

<style scoped>
.latest-news-container__background--color {
  background-color: var(--cs-gray-02);
}
</style>
