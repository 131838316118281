<template>
  <div class="community-container__background--color">
    <div class="container">
      <div class="row">
        <div v-if="loading" class="col-md-12 library-container__loading-holder">
          <cs-spinner />
        </div>
        <div class="col-md-12">
          <app-latest-news
            :news="news"
            v-if="news && news.length > 0"
            :isViewMore="totalNews && totalNews.aggregate.count > limit.news"
          ></app-latest-news>
          <app-latest-testimonies
            :testimonies="testimonies"
            v-if="testimonies && testimonies.length > 0"
            :isViewMore="
              totalTestimonies &&
              totalTestimonies.aggregate.count > limit.testimonies
            "
          ></app-latest-testimonies>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLatestNews from '@/components/community/AppLatestNews.vue';
import AppLatestTestimonies from '@/components/community/AppLatestTestimonies.vue';
import ListNews from '@/gql/news/ListNews.gql';
import ListTestimonies from '@/gql/testimonies/ListTestimonies.gql';

export default {
  components: {
    AppLatestNews,
    AppLatestTestimonies,
  },
  computed: {
    loading() {
      const queries = ['totalNews', 'news', 'totalTestimonies', 'testimonies'];
      const loadingQueries = queries.filter(
        (query) => this.$apollo.queries[query].loading
      );
      return loadingQueries.length > 0;
    },
  },
  data() {
    return {
      limit: {
        news: 6,
        testimonies: 3,
      },
    };
  },
  apollo: {
    totalNews: {
      query: ListNews,
      variables() {
        return {
          language: 'en',
        };
      },
    },
    news: {
      query: ListNews,
      variables() {
        return {
          language: 'en',
          limit: this.limit.news,
        };
      },
    },
    totalTestimonies: ListTestimonies,
    testimonies: {
      query: ListTestimonies,
      variables() {
        return {
          limit: this.limit.testimonies,
        };
      },
    },
  },
};
</script>

<style scoped>
.community-container__background--color {
  background-color: var(--cs-gray-02);
}
</style>
