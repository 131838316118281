<template>
  <div class="app-group-search__div-main">
    <div class="app-group-search__div-search">
      <i class="cs-icons-search"></i>
      <input
        type="search"
        :placeholder="placeholder"
        v-model="query"
        @keyup.enter="goToFindGroups()"
      />
    </div>
    <router-link :to="{ name: 'findGroups' }" active-class="">
      <i
        class="
          cs-icons-map
          app-group-search__searchbar_icon
          cs-textstyle-page-title
        "
      ></i>
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      val: '',
      placeholder: this.isBack ? 'Search' : 'Find groups near you',
      query: '',
    };
  },
  methods: {
    goBack() {
      // this.$router.go(-1);
      this.$router.push('/Groups');
    },
    goToFindGroups() {
      this.$router.push(`/GroupFinder?query=${this.query}`);
      this.$emit('searchQuery', this.query);
    },
  },
  mounted() {
    this.query = this.$route.query.query ? this.$route.query.query : '';
    this.$emit('searchQuery', this.query);
  },
};
</script>

<style scoped>
.app-group-search__div-main {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
.app-group-search__div-search {
  flex: 1;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: solid 1px var(--cs-gray-03);
  border-radius: 4px;
  padding-left: 10px;
  overflow: hidden;
  background: var(--cs-gray-02);
}
.app-group-search__div-search i {
  cursor: pointer;
}
.app-group-search__div-search input {
  width: 100%;
  border: none;
  height: 38px;
  padding: 5px;
  outline-width: 0;
  background-color: var(--cs-gray-02);
}
.app-group-search__back-button {
  margin-top: 7px;
  margin-right: 10px;
  color: var(--cs-primary-base);
}
.app-group-search__searchbar_icon {
  color: var(--cs-primary-base);
}
</style>
