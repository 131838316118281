<template>
  <div class="contact-us__div--color">
    <div class="container contact-us__div-container-color">
      <b-row>
        <b-col>
          <h3 class="cs-textstyle-section-heading">About Us</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="contact-us__description">
          <p class="cs-textstyle-paragraph">
            The greatest Friday in history changed our lives. When Jesus hung on
            the cross and shouted: "it is finished", the price for salvation was
            paid for everyone who believes. We will go out and share this
            wonderful gospel every Friday in every city. It will change us and
            the people we will meet, Christians will be mobilized, encouraged,
            trained and will go out together. The world will see us and the
            gospel will be heard every week.
          </p>
        </b-col>
      </b-row>
      <b-row class="form-group">
        <div class="col-md-7">
          <h3 class="cs-textstyle-section-heading">Get in Touch</h3>
          <div class="cs-textstyle-paragraph">
            Please fill the details below
          </div>
          <div class="contact-us__div-enquries-color">
            <cs-form class="cs-textstyle-paragraph" v-model="formValid">
              <div class="row">
                <cs-input
                  label="Full Name"
                  type="text"
                  v-model="fullName"
                  required
                />
              </div>
              <div class="row">
                <cs-input
                  label="Email Address"
                  type="email"
                  v-model="email"
                  required
                />
              </div>
              <div class="row">
                <cs-input
                  label="Phone Number"
                  type="text"
                  v-model="phone"
                  required
                />
              </div>
              <div class="row">
                <cs-textarea
                  label="Description"
                  v-model="description"
                  placeholder="Add any message here"
                  required
                />
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                  <cs-button
                    variant="danger"
                    fill="solid"
                    block="block"
                    size="medium"
                    corners="round"
                    :disabled="!formValid"
                    @click="onSendMessage"
                    >Send Message</cs-button
                  >
                </div>
              </div>
              <br />
              <div class="row" v-if="onSendMessageSuccess">
                <div class="col-md-12">
                  <cs-alert
                    variant="success"
                    title="Thank you for contacting us."
                  >
                  </cs-alert>
                </div>
              </div>
            </cs-form>
          </div>
        </div>
        <div class="col-md-5">
          <h3 class="cs-textstyle-section-heading">Contact Us</h3>
          <div class="contact-us__div-enquries-color">
            <p class="cs-textstyle-paragraph">
              <i class="cs-icons-website"></i>&nbsp;&nbsp;&nbsp;
              <label
                ><a href="https://www.fridayoutreach.com" target="_blank"
                  >www.fridayoutreach.com</a
                ></label
              >
            </p>
            <p class="cs-textstyle-paragraph">
              <i class="cs-icons-email"></i>&nbsp;&nbsp;&nbsp;
              <label
                ><a href="mailto:info@fridayoutreach.com"
                  >info@fridayoutreach.com</a
                ></label
              >
            </p>
            <p class="cs-textstyle-paragraph">
              <i class="cs-icons-phone"></i>&nbsp;&nbsp;&nbsp;
              <label><a href="tel:+49 069 24247730">+49 069 24247730</a></label>
            </p>
            <p class="cs-textstyle-paragraph">
              <i class="cs-icons-marker"></i>&nbsp;&nbsp;&nbsp; Friday Outreach
              e.VKaiserstraße 7260329 Frankfurt am MainGermany
            </p>
          </div>
          <h3 class="form-group cs-textstyle-section-heading">Bank Details</h3>
          <div class="contact-us__div-enquries-color">
            <p class="cs-textstyle-paragraph">
              <label
                >Friday Outreach e.V.<br />
                Evangelische Bank<br />
                BIC: GENODEF1EK1<br />
                Account: DE05 5206 0410 0005 0291 71
              </label>
            </p>
          </div>
          <h3 class="form-group cs-textstyle-section-heading">Address</h3>
          <div class="contact-us__div-enquries-color">
            <p>
              <label class="cs-textstyle-paragraph"
                >Friday Outreach e.V.,<br />
                Kaiserstraße 72,<br />
                60329 Frankfurt am Main, Germany.<br />
              </label>
            </p>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<style scoped>
.contact-us__div--color {
  background-color: var(--cs-gray-02);
  padding: 20px;
}
.contact-us__description {
  color: var(--cs-gray-05);
}
.contact-us__div-container-color {
  background-color: var(--cs-gray-00);
  padding: 10px;
}
.contact-us__div-enquries-color {
  background-color: var(--cs-gray-01);
  padding: 20px 30px 10px 30px;
  margin-bottom: 10px;
}
.contact-us__div-enquries-color i {
  color: var(--cs-gray-03);
}
.form-group {
  padding-top: 20px;
}
@media (max-width: 768px) {
  .contact-us__div-container-color {
    padding: 10px;
  }
}
</style>

<script>
import SendEnquiryEmail from '@/gql/general/SendEnquiryEmail.gql';

export default {
  data() {
    return {
      fullName: '',
      email: '',
      phone: '',
      description: '',
      formValid: false,
      onSendMessageSuccess: false,
    };
  },
  methods: {
    async onSendMessage() {
      await this.$apollo.mutate({
        mutation: SendEnquiryEmail,
        variables: {
          email: this.email,
          message: this.description,
          name: this.fullName,
          phone: this.phone,
        },
      });
      this.onSendMessageSuccess = true;
    },
  },
};
</script>
