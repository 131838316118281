<template>
  <div class="app-latest-news__div--main">
    <app-section-title
      :title="title"
      :isViewMore="isViewMore"
      to="latestNews"
    ></app-section-title>
    <div class="container">
      <div class="row">
        <div
          class="col-md-4 app-latest-news__div--top"
          v-for="resource in news"
          :key="resource.id"
        >
          <app-news-card
            :heading="resource.category"
            :updatedTime="resource.updated_at"
            :libraryHeader="resource.title"
            :libraryDescription="resource.summary"
            :type="resource.attachment_type"
            :updatedDate="resource.updated_at"
            :picture="resource.picture"
            @click="onGoToNewsItem(resource.id)"
          ></app-news-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppNewsCard from './cards/AppNewsCard.vue';
import AppSectionTitle from '../general/AppSectionTitle.vue';

export default {
  components: {
    AppNewsCard,
    AppSectionTitle,
  },
  props: {
    news: {
      type: Array,
    },
    isViewMore: {
      default: false,
    },
  },
  data() {
    return {
      title: 'Latest News',
    };
  },
  methods: {
    onGoToNewsItem(id) {
      this.$router.push(`/Community/LatestNews/${id}`);
    },
  },
};
</script>

<style scoped>
.app-latest-news__div--main {
  margin-top: 20px;
}
.container {
  padding: 10px 0px 10px 0px;
}
.app-latest-news__div--top {
  margin-top: 10px;
}
</style>
