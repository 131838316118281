<template>
  <div class="d-flex justify-content-center">
    <div class="app-participant-report__main-container">
      <div class="d-flex">
        <div class="app-participant-report__icon">
          <div class="cs-textstyle-page-title">
            <i class="cs-icons-group"></i>
          </div>
        </div>
        <div class="app-participant-report__div_left">
          <h2 class="cs-textstyle-page-subtitle">{{ participants }}</h2>
          <div class="cs-textstyle-paragraph-bold">Participants</div>
        </div>
      </div>
    </div>
    <div
      class="app-participant-report__main-container"
      @click="onGoToPage('/Library')"
    >
      <div class="d-flex">
        <div class="app-participant-report__icon">
          <div class="cs-textstyle-page-title">
            <i class="cs-icons-group"></i>
          </div>
        </div>
        <div class="app-participant-report__div_left">
          <h2 class="cs-textstyle-page-subtitle">{{ resources }}</h2>
          <div class="cs-textstyle-paragraph-bold">Resources</div>
        </div>
      </div>
    </div>
    <div
      class="app-participant-report__main-container"
      @click="onGoToPage('/Library')"
    >
      <div class="d-flex">
        <div class="app-participant-report__icon">
          <div class="cs-textstyle-page-title">
            <i class="cs-icons-members"></i>
          </div>
        </div>
        <div class="app-participant-report__div_left">
          <h2 class="cs-textstyle-page-subtitle">{{ groups }}</h2>
          <div class="cs-textstyle-paragraph-bold">Groups</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    participants: {
      type: Number,
    },
    resources: {
      type: Number,
    },
    groups: {
      type: Number,
    },
  },
  methods: {
    onGoToPage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style scoped>
.app-participant-report__main-container {
  background-color: var(--cs-danger-dark);
  padding: 31px 30px 31px 30px;
  border-radius: 5px;
  margin: 10px;
  width: 260px;
  color: var(--cs-gray-00);
}
.app-participant-report__main-container:not(:first-child) {
  cursor: pointer;
}
.app-participant-report__icon {
  padding: 7px;
  background-color: var(--cs-primary-light);
  border-radius: 2px;
}
.app-participant-report__div_left {
  padding-left: 10px;
}
@media (max-width: 768px) {
  .d-flex {
    flex-direction: column;
    padding: 5px;
  }
  .app-participant-report__main-container .d-flex {
    flex-direction: row;
  }
  .app-participant-report__main-container {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }
}
</style>
