<template>
  <div v-if="leader">
    <cs-profile
      :picture="leader.picture"
      :name="leader.name"
      avatar-position="left"
    >
      <cs-social-icon-button
        v-if="leader.facebook"
        :href="`https://facebook.com/${leader.facebook}`"
        :network="'facebook'"
        :size="'small'"
        slot="social-icons"
      ></cs-social-icon-button>

      <cs-social-icon-button
        v-if="leader.twitter"
        :href="`https://twitter.com/${leader.twitter}`"
        :network="'twitter'"
        :size="'small'"
        slot="social-icons"
      ></cs-social-icon-button>

      <cs-social-icon-button
        :href="'mailto:' + leader.email"
        :network="'email'"
        :size="'small'"
        slot="social-icons"
      ></cs-social-icon-button>
    </cs-profile>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    leader: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.cs-profile {
  display: block !important;
  margin-bottom: 16px;
}
.cs-profile >>> .cs-profile__content-social {
  margin-top: 0;
}
</style>
