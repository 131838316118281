<template>
  <b-card border-variant="light">
    <div class="d-flex justify-content-between">
      <div>
        <cs-profile
          @click="ShowGroupProfile"
          :picture="picture"
          :name="name"
          :detail="detail"
          avatar-position="left"
          avatar-size="medium"
        >
        </cs-profile>
      </div>
      <div class="group-near-card_div-left cs-textstyle-paragraph-small">
        {{ Math.round(distance) }} km
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    id: null,
    name: {},
    picture: {},
    detail: {},
    eventdetails: {},
    attendant: {},
    distance: {},
  },
  methods: {
    ShowGroupProfile() {
      this.$router.push(`/GroupProfile/${this.id}`);
    },
  },
};
</script>

<style scoped>
.group-near-card_div-left {
  padding-left: 5px;
  color: var(--cs-gray-04);
}
</style>
