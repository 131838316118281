<template>
  <div>
    <div class="home-main__div--center">
      <div class="cs-character-style-3">
        <span>EVERY </span>
        <span class="home-main__span-title__color">CITY.</span><br />
        <span class="">EVERY </span
        ><span class="home-main__span-title__color">BELIEVER.</span>
      </div>
      <div class="home-main__subtitle">
        <h4 class="cs-textstyle-item-heading">
          Every Friday - Christians Share Jesus!
        </h4>
      </div>
      <div class="home-main__download-button">
        <cs-button
          variant="danger"
          fill="solid"
          block="block"
          size="medium"
          corners="round"
          href="https://app.fridayoutreach.com"
          target="_blank"
          >Download App</cs-button
        >
      </div>
      <h4 class="home-main__social-media cs-textstyle-item-heading">
        <a
          href="https://www.facebook.com/Fridayoutreachgermany"
          target="_blank"
        >
          <i class="cs-icons-facebook-filled"></i>
        </a>
        <a href="https://www.instagram.com/fridayoutreach" target="_blank">
          <i class="cs-icons-instagram"></i>
        </a>
        <a href="mailto:info@fridayoutreach.com" target="_blank">
          <i class="cs-icons-send-filled"></i>
        </a>
      </h4>
    </div>
    <div
      class="home-main__div--report--margin"
      v-if="participantsCount && participantsCount.aggregate"
    >
      <app-participant-report
        :participants="participantsCount.aggregate.count"
        :resources="resourcesCount.aggregate.count"
        :groups="groupsCount.aggregate.count"
      ></app-participant-report>
    </div>
  </div>
</template>

<script>
import AppParticipantReport from './AppParticipantReport.vue';
import ParticipationStats from '@/gql/general/ParticipationStats.gql';

export default {
  components: {
    AppParticipantReport,
  },
  apollo: {
    participantsCount: ParticipationStats,
    resourcesCount: ParticipationStats,
    groupsCount: ParticipationStats,
  },
};
</script>

<style scoped>
.home-main__div--center {
  text-align: center;
  color: var(--cs-gray-00);
  margin: 0;
  top: 50%;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 70px;
}
.home-main__span-title__color {
  color: var(--cs-danger-base);
}
.home-main__subtitle {
  margin-top: 34px;
}
.home-main__download-button {
  padding-top: 150px;
  text-align: center;
  width: 50%;
  margin: auto;
}
.home-main__div--report--margin {
  margin-top: 827px;
}
@media (max-width: 768px) {
  .home-main__div--center {
    top: 35%;
  }
  .home-main__download-button {
    width: auto;
    padding-top: 20px;
  }
  .home-main__div--center {
    margin-top: 30px;
  }
  .home-main__div--report--margin {
    margin-top: 475px;
  }
}
.home-main__social-media {
  padding: 30px 20px 0px 20px;
}
.home-main__social-media i {
  margin: 15px;
  color: white;
  font-size: 25px;
}
</style>
