<template>
  <div class="app-get-involved__main--container">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="app-get-involved__icon cs-textstyle-page-title">
            <i class="cs-icons-map"></i>
          </div>
          <h2
            class="
              cs-textstyle-page-subtitle
              app-get-involved__news--update__top
            "
          >
            HOW TO GET INVOLVED
          </h2>
          <div class="cs-textstyle-informative-paragraph">
            <span>To find a meeting point close to you, use the </span>
            <router-link :to="{ name: 'groups' }">group finder</router-link>
            <span> or install the </span>
            <a href="https://app.fridayoutreach.com">Friday Outreach App</a>
            <span>.</span>
          </div>
          <h3
            class="
              cs-textstyle-section-heading
              app-get-involved__news--update__top
            "
          >
            Schedule
          </h3>
          <div class="cs-textstyle-informative-paragraph">
            Friday Outreach starts in the evening with prayer, 45 mins
            encouragement and teaching, 90 mins outreach (two by two) and 30
            mins feedback and testimonies.
          </div>
          <h3
            class="
              cs-textstyle-section-heading
              app-get-involved__news--update__top
            "
          >
            Start a Meeting Point
          </h3>
          <div class="cs-textstyle-informative-paragraph">
            To start a meeting point, either
            <a href="mailto:info@fridayoutreach.com">email us</a>, or install
            the
            <a href="https://app.fridayoutreach.com">Friday Outreach App</a>.
          </div>
        </div>
        <div class="col-md-6" v-if="groups">
          <app-google-map :groups="groups"></app-google-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGoogleMap from '../general/AppGoogleMap.vue';

export default {
  components: {
    AppGoogleMap,
  },
  props: {
    groups: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.app-get-involved__main--container {
  background-color: var(--cs-gray-02);
  padding: 50px 0px 50px 0px;
}
.app-get-involved__news--update__top {
  padding-top: 30px;
}
.app-get-involved__icon {
  background-color: var(--cs-primary-dark);
  width: 52px;
  height: 55px;
  padding: 8px 0px 0px 10px;
  color: var(--cs-gray-00);
}
@media (max-width: 768px) {
  .app-get-involved__icon {
    padding: 15px 0px 0px 15px;
  }
}
</style>
