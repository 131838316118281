<template>
  <div class="d-flex justify-content-between section-title">
    <h3 class="cs-textstyle-item-heading">
      {{ title }}
    </h3>
    <div v-if="isViewMore" class="app-section-title__div--color">
      <router-link :to="{ name: to }" active-class=""
        ><a>View More</a></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    isViewMore: {
      default: true,
    },
    to: {
      default: 'Home',
    },
  },
};
</script>

<style scoped>
.app-section-title__div--color a {
  color: var(--cs-danger-base);
}
.section-title {
  border-bottom: solid 1px var(--cs-gray-03);
}
</style>
