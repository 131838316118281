<template>
  <div class="app-library-filter__main--div">
    <!-- <div class="container app-library-filter__border__bottom">
      <div class="row">
        <div class="col-md-12">
          <h3 class="app-library-filter__heading-top cs-textstyle-label-text">
            Categories
          </h3>
        </div>
      </div>
    </div> -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="app-library-filter__heading-top">
            <ul class="app-library-filter__ul">
              <li>
                <a
                  class="cs-textstyle-paragraph-small-bold"
                  :class="{ active: selectedFilter === null }"
                  @click="onSelectFilter(null)"
                  >All</a
                >
              </li>
              <li v-for="category in categories" :key="category.id">
                <a
                  class="cs-textstyle-paragraph-small-bold"
                  :class="{ active: selectedFilter === category.id }"
                  @click="onSelectFilter(category.id)"
                >
                  {{ category.name }}
                  <b class="app-library-filter__ul__new" v-if="false"
                    >&nbsp;&nbsp;New</b
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-library-filter__main--div {
  margin-top: 20px;
  border-radius: 4px;
  background-color: var(--cs-gray-00);
  border: 1px solid var(--cs-gray-01);
}
.app-library-filter__border__bottom {
  border-bottom: 2px solid var(--cs-gray-01);
  padding-bottom: 10px;
}
.app-library-filter__heading-top {
  padding: 10px 0px 0px 0px;
}
.app-library-filter__ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.app-library-filter__ul li {
  padding: 5px 0px 5px 0px;
}
.app-library-filter__ul__new {
  color: var(--cs-primary-base);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

li a {
  display: block;
  padding: 0px 0px 0px 8px;
  text-decoration: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

li {
  text-align: left;
}
li a {
  color: var(--cs-gray-06);
}

li a.active {
  border-left: 3px solid var(--cs-primary-base);
  text-decoration: none;
  color: var(--cs-primary-base);
  padding-left: 5px;
}

li a:hover:not(.active) {
  border-left: 3px solid var(--cs-primary-base);
  text-decoration: none;
  color: var(--cs-primary-base);
  padding-left: 5px;
}
.app-library-filter__main--div .container:not(:first-child) {
  padding-left: 0px;
}
</style>

<script>
export default {
  data() {
    return {
      selectedFilter: null,
    };
  },
  props: {
    categories: {
      type: Array,
    },
  },
  methods: {
    onSelectFilter(filterId) {
      this.selectedFilter = filterId;
      this.$emit('selectedFilter', filterId);
    },
  },
};
</script>
