<template>
  <div class="app-event-details__main--container">
    <div class="cs-textstyle-page-title">Passion of Christ- Night Vigil</div>
    <h2 class="cs-textstyle-page-subtitle app-event-details__header-summary">
      CEO Women Community
    </h2>
    <img
      class="app-event-details__banner_image"
      src="@/assets/Image_Block.png"
    />
    <div class="container app-event-details__container">
      <div class="row">
        <div class="col-md-9">
          <h5 class="cs-textstyle-detail-heading">DESCRIPTION</h5>
          <div class="cs-textstyle-informative-paragraph">
            <p>
              Stay Connected! Be Encouraged! Particiate in a Life Group with
              other Single Chrissian Men and Woman over Zoom - it doesn't matter
              where you physically live.
            </p>
            <p>
              For the next serveral month, each Sunday, this life group will be
              using the book "Messy People: Life Lession from Imperfect
              Bibilical Heroes".
            </p>
            <p>
              11:45 AM to about 12:05 PM - virtual 'coffee' - introductions,
              fellowship and catching up with one another about our past week.
            </p>
            <p>12:10 PM - announcement about upcoming events</p>
            <p>The study and discussion will begin by 12:15 PM</p>
            <p>
              Please join us whenever you are able, even if you cannot joint at
              the zoom meeting
            </p>
          </div>
          <h5 class="cs-textstyle-detail-heading">ATTENDES</h5>
          <div>
            <b-avatar-group size="30px">
              <b-avatar
                src="https://placekitten.com/320/320"
                variant="dark"
              ></b-avatar>
              <b-avatar text="www" variant="primary"></b-avatar>
              <b-avatar
                src="https://placekitten.com/320/320"
                variant="dark"
              ></b-avatar>
              <b-avatar
                src="https://placekitten.com/300/300"
                variant="info"
              ></b-avatar>
              <b-avatar
                src="https://placekitten.com/320/320"
                variant="dark"
              ></b-avatar>
              <b-avatar text="+5" variant="primary"></b-avatar>
            </b-avatar-group>
          </div>
          <div class="app-event-details__div--share">
            <div class="app-event-details__share_details">SHARE</div>
            <app-social-media></app-social-media>
          </div>
        </div>
        <div class="col-md-3">
          <h5 class="cs-textstyle-detail-heading">
            <b-icon icon="calendar3"></b-icon> Date
          </h5>
          <div class="cs-textstyle-item-name app-event-details__padding_left">
            14 May 2021
          </div>
          <h5 class="cs-textstyle-detail-heading">
            <b-icon icon="clock"></b-icon> Time
          </h5>
          <div class="cs-textstyle-item-name app-event-details__padding_left">
            6:00-9:00 PM UTC
          </div>
          <div class="cs-textstyle-item-name">
            <b-icon icon="geo-alt"></b-icon> Building A ul. Fabryczna
          </div>
          <h5
            class="cs-textstyle-detail-heading app-event-details__padding_top"
          >
            HOSTED BY
          </h5>
          <app-hosted-by class="cs-textstyle-item-name"></app-hosted-by>
          <app-hosted-by class="cs-textstyle-item-name"></app-hosted-by>
          <app-hosted-by class="cs-textstyle-item-name"></app-hosted-by>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSocialMedia from '@/components/general/AppSocialMedia.vue';
import AppHostedBy from '@/components/groups/AppHostedBy.vue';

export default {
  components: {
    AppSocialMedia,
    AppHostedBy,
  },
  props: {},
  data() {
    return {};
  },
};
</script>

<style scoped>
.app-event-details__main--container {
  margin-top: 20px;
}
.app-event-details__header-summary {
  color: var(--cs-gray-04);
  margin-bottom: 20px;
}
.app-event-details__banner_image {
  width: 100%;
}
.app-event-details__div--share {
  display: flex;
}
.app-event-details__share_details {
  padding-top: 7px;
  padding-right: 15px;
  color: var(--cs-gray-04);
}
.app-event-details__padding_left {
  padding-left: 15px;
}
.app-event-details__padding_top {
  padding-top: 50px;
}
.app-event-details__container {
  background-color: var(--cs-gray-00);
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
