<template>
  <b-card class="card-body-container">
    <div
      v-if="showHeaderTagOnTop"
      :class="{ 'header-tag': showHeaderTagOnTop }"
    >
      <div v-if="heading">
        <cs-tag>{{ heading }}</cs-tag>
      </div>
    </div>
    <div v-if="showHeaderTagOnTop">
      <div>
        <h4 class="app-news-card__div__color cs-textstyle-detail-heading">
          {{ libraryHeader }}
        </h4>
      </div>
      <div class="app-news-card__library__header">
        <div class="cs-textstyle-page-title">
          <i
            class="cs-icons-pdf-filled"
            v-if="utils.isAttachmentType(type, 'pdf')"
          ></i>
          <i
            class="cs-icons-audio-filled"
            v-else-if="utils.isAttachmentType(type, 'audio')"
          ></i>
          <i
            class="cs-icons-video-file"
            v-else-if="utils.isAttachmentType(type, 'video')"
          ></i>
          <i class="cs-icons-application-file" v-else></i>
        </div>
        <div class="cs-textstyle-paragraph app-news-card__div--padding">
          {{ libraryDescription }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="app-news-card__library__header">
        <div>
          <img class="app-news-card__image" :src="picture" />
        </div>
        <div class="cs-textstyle-paragraph app-news-card__div--padding">
          <div>
            <cs-tag>{{ heading }}</cs-tag>
          </div>
          <h4 class="app-news-card__div__color cs-textstyle-detail-heading">
            {{ libraryHeader }}
          </h4>
          {{ libraryDescription }}
        </div>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <div class="app-news-card__footer--div cs-textstyle-paragraph-bold">
        <div>{{ updatedDate | moment('from', 'now') }}</div>
      </div>
      <div class="app-news-card__footer--div cs-textstyle-paragraph-bold">
        <div>{{ utils.readingTime(libraryDescription) }} min read</div>
      </div>
    </div>
  </b-card>
</template>

<script>
import utils from '@/services/utils';

export default {
  components: {},
  props: {
    heading: {
      type: String,
    },
    updatedTime: {
      type: String,
    },
    updatedDate: {
      type: String,
    },
    libraryHeader: {
      type: String,
    },
    libraryDescription: {
      type: String,
    },
    type: {
      type: String,
    },
    picture: {
      type: String,
    },
  },
  data() {
    return {
      utils,
    };
  },
  computed: {
    showHeaderTagOnTop() {
      return !this.picture;
    },
  },
};
</script>

<style scoped>
.card-body-container {
  cursor: pointer;
}
.app-news-card__div__color {
  color: var(--cs-gray-07);
}
.app-news-card__library__header {
  display: flex;
}
.app-news-card__library__header i {
  color: var(--cs-gray-03);
}
.app-news-card__div--padding {
  padding: 0px 0px 15px 5px;
}
.app-news-card__footer--div {
  color: var(--cs-gray-04);
}
hr {
  margin: 5px;
  color: var(--cs-gray-03);
}
.app-news-card__image {
  width: 83px;
  height: 95px;
}
.header-tag {
  margin-bottom: 8px;
}
</style>
