<template>
  <div class="upcoming-event-detail__main-div">
    <div class="container upcoming-event-detail__main-div">
      <div class="row">
        <div class="col-md-8">
          <app-group-search :isBack="isBack"></app-group-search>
          <app-group-location></app-group-location>
        </div>
        <div class="col-md-4">
          <app-groups-near-list></app-groups-near-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupSearch from '@/components/groups/AppGroupSearch.vue';
import AppGroupsNearList from '@/components/groups/AppGroupsNearList.vue';
import AppGroupLocation from '@/components/groups/AppGroupLocation.vue';

export default {
  components: {
    AppGroupSearch,
    AppGroupsNearList,
    AppGroupLocation,
  },
  data() {
    return {
      isBack: true,
    };
  },
};
</script>

<style scoped>
.upcoming-event-detail__main-div {
  background-color: var(--cs-gray-02);
}
</style>
