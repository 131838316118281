<template>
  <img
    class="app-group-location__image"
    src="@/assets/MicrosoftTeams-image.png"
  />
</template>

<script>
export default {};
</script>

<style scoped>
.app-group-location__image {
  width: 100%;
}
</style>
