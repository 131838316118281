<template>
  <div class="group-profile-container__main-div">
    <div class="container group-profile-container__main-div">
      <div class="row">
        <div class="col-md-12">
          <app-group-profile :group="group"></app-group-profile>
          <app-meets-at :group="group"></app-meets-at>
          <app-popular-groups :popularGroups="groups"></app-popular-groups>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppGroupProfile from '@/components/groups/AppGroupProfile.vue';
import AppMeetsAt from '@/components/groups/AppMeetsAt.vue';
import AppPopularGroups from '@/components/groups/AppPopularGroups.vue';
import GetGroup from '@/gql/groups/GetGroup.gql';
import ListGroups from '@/gql/groups/ListGroups.gql';

export default {
  components: {
    AppGroupProfile,
    AppPopularGroups,
    AppMeetsAt,
  },
  data() {
    return {};
  },
  apollo: {
    group: {
      query: GetGroup,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    groups: ListGroups,
  },
};
</script>

<style scoped>
.group-profile-container__main-div {
  background-color: var(--cs-gray-02);
}
</style>
