import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home/Home.vue';
import ContactUs from '../views/Contact/ContactUs.vue';
import LibraryContainer from '../views/Library/LibraryContainer.vue';
import PopularResources from '../views/Library/PopularResources.vue';
import TrainingArticle from '../views/Library/TrainingArticle.vue';
import StoreProducts from '../views/Library/StoreProducts.vue';
import StoreProduct from '../views/Library/StoreProduct.vue';
import RecentVideos from '../views/Library/RecentVideos.vue';
import GroupContainer from '../views/Groups/GroupsContainer.vue';
import FindGroups from '../views/Groups/FindGroups.vue';
import UpcomingEventDetail from '../views/Groups/UpcomingEventDetail.vue';
import EventDetail from '../views/Groups/EventDetailContainer.vue';
import GroupProfile from '../views/Groups/GroupProfileContainer.vue';
import CommunityContainer from '../views/Community/CommunityContainer.vue';
import LatestNews from '../views/Community/LatestNews.vue';
import NewsItem from '../views/Community/NewsItem.vue';
import LatestTestimonies from '../views/Community/LatestTestimonies.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Contact',
    name: 'contact',
    component: ContactUs,
  },
  {
    path: '/Library',
    name: 'library',
    component: LibraryContainer,
  },
  {
    path: '/Library/PopularResources',
    name: 'popularResources',
    component: PopularResources,
  },
  {
    path: '/Library/PopularResources/:id',
    name: 'trainingArticle',
    component: TrainingArticle,
  },
  {
    path: '/Library/StoreProducts',
    name: 'storeProducts',
    component: StoreProducts,
  },
  {
    path: '/Library/Store/:id',
    name: 'storeProduct',
    component: StoreProduct,
  },
  {
    path: '/Library/RecentVideos',
    name: 'recentVideos',
    component: RecentVideos,
  },
  {
    path: '/Groups',
    name: 'groups',
    component: GroupContainer,
  },
  {
    path: '/GroupFinder',
    name: 'findGroups',
    component: FindGroups,
  },
  {
    path: '/EventDetails',
    name: 'eventdetails',
    component: UpcomingEventDetail,
  },
  {
    path: '/UpcomingEventDetails',
    name: 'upcomingeventdetails',
    component: EventDetail,
  },
  {
    path: '/GroupProfile/:id',
    name: 'groupprofile',
    component: GroupProfile,
  },
  {
    path: '/Community',
    name: 'community',
    component: CommunityContainer,
  },
  {
    path: '/Community/LatestNews',
    name: 'latestNews',
    component: LatestNews,
  },
  {
    path: '/Community/LatestNews/:id',
    name: 'newsItem',
    component: NewsItem,
  },
  {
    path: '/Community/LatestTestimonies',
    name: 'latestTestimonies',
    component: LatestTestimonies,
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
