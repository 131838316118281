<template>
  <div>
    <b-card
      v-if="
        utils.isAttachmentType(type, 'pdf') ||
        utils.isAttachmentType(type, 'audio') ||
        utils.isAttachmentType(type, 'video') ||
        type === null
      "
      class="card-body-container"
    >
      <div>
        <cs-tag>{{ heading }}</cs-tag>
      </div>
      <div>
        <h5 class="cs-textstyle-detail-heading">{{ libraryHeader }}</h5>
      </div>
      <div class="app-card-container__div-flex">
        <div class="app-card-container__div-image" v-if="picture">
          <img :src="picture" width="84px" height="100%" />
        </div>
        <div class="cs-textstyle-page-title" v-else>
          <i
            class="cs-icons-pdf-filled"
            v-if="utils.isAttachmentType(type, 'pdf')"
          ></i>
          <i
            class="cs-icons-audio-filled"
            v-else-if="utils.isAttachmentType(type, 'audio')"
          ></i>
          <i
            class="cs-icons-video-file"
            v-else-if="utils.isAttachmentType(type, 'video')"
          ></i>
          <i class="cs-icons-application-file" v-else></i>
        </div>
        <div class="cs-textstyle-paragraph">{{ libraryDescription }}</div>
      </div>
      <hr class="app-card-container__separator" />
      <div
        class="
          d-flex
          justify-content-between
          cs-textstyle-paragraph-extra-small
          app-card-container__footer-details
        "
      >
        <div>{{ updatedTime | moment('from', 'now') }}</div>
        <div>{{ utils.readingTime(libraryDescription) }} min read</div>
      </div>
    </b-card>
  </div>
</template>

<script>
import utils from '@/services/utils';

export default {
  props: {
    heading: {
      type: String,
    },
    updatedTime: {
      type: String,
    },
    libraryHeader: {
      type: String,
    },
    libraryDescription: {
      type: String,
    },
    type: {
      type: String,
    },
    picture: {
      type: String,
    },
    attachments: {
      type: Array,
    },
  },
  data() {
    return {
      utils,
    };
  },
};
</script>

<style scoped>
.card-body-container {
  cursor: pointer;
}
.app-card-container__div-flex {
  display: flex;
}
.app-card-container__div-flex i {
  color: var(--cs-gray-03);
}
.video-player {
  width: 100%;
  height: 100%;
  min-height: 0px;
}
.video-js .vjs-progress-control {
  min-width: 0em;
}
.video-js .vjs-time-control {
  min-width: 1px;
  width: 10px;
}
.video-js .vjs-control {
  width: 1em !important;
}
.vjs-big-play-button {
  font-size: 0.5em !important;
  width: 1em !important;
}
.app-card-container__div-video {
  width: 30%;
  padding-right: 5px;
  margin-top: 5px;
}
.app-card-container__div-video-discription {
  width: 70%;
}
.app-card-container__div-image {
  width: 30%;
  padding-right: 5px;
  margin-top: 5px;
}
.app-card-container__div-image-discription {
  width: 70%;
}
.app-card-container__footer-details {
  color: var(--cs-gray-04);
}
.app-card-container__separator {
  color: var(--cs-gray-02);
  margin: 0.25rem 0;
}
</style>
