import { render, staticRenderFns } from "./AppPopularResources.vue?vue&type=template&id=492914cb&scoped=true&"
import script from "./AppPopularResources.vue?vue&type=script&lang=js&"
export * from "./AppPopularResources.vue?vue&type=script&lang=js&"
import style0 from "./AppPopularResources.vue?vue&type=style&index=0&id=492914cb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "492914cb",
  null
  
)

export default component.exports